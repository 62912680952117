// Generated by Framer (f558e44)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["TpEg7x7Be"];

const serializationHash = "framer-0BaJA"

const variantClassNames = {TpEg7x7Be: "framer-v-rqjeqh"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, Xsd9dMzIn: image ?? props.Xsd9dMzIn ?? {src: "https://framerusercontent.com/images/pT3Iq4GDPnEmM2Xgf5btrfCN03E.webp"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Xsd9dMzIn, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "TpEg7x7Be", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Image {...restProps} animate={variants} background={{alt: "", fit: "fill", intrinsicHeight: 124.5, intrinsicWidth: 175, pixelHeight: 249, pixelWidth: 350, sizes: "min(175px, 100vw)", ...toResponsiveImage(Xsd9dMzIn)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rqjeqh", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"TpEg7x7Be"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition>
</LayoutGroup>)

});

const css = [".framer-0BaJA[data-border=\"true\"]::after, .framer-0BaJA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-0BaJA.framer-752euk, .framer-0BaJA .framer-752euk { display: block; }", ".framer-0BaJA.framer-rqjeqh { height: 125px; overflow: visible; position: relative; width: 175px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 124.5
 * @framerIntrinsicWidth 175
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Xsd9dMzIn":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerruwKWi1dO: React.ComponentType<Props> = withCSS(Component, css, "framer-0BaJA") as typeof Component;
export default FramerruwKWi1dO;

FramerruwKWi1dO.displayName = "Illustrations / Documents";

FramerruwKWi1dO.defaultProps = {height: 124.5, width: 175};

addPropertyControls(FramerruwKWi1dO, {Xsd9dMzIn: {__defaultAssetReference: "data:framer/asset-reference,pT3Iq4GDPnEmM2Xgf5btrfCN03E.webp?originalFilename=Layer+1-2.webp&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerruwKWi1dO, [])